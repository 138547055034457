export const banners = {
  namespaced: true,
  state: {
    banners: [],

  },
  getters: {
    getBanners(state) {
      return state.banners;
    },
  },
  mutations: {
    setBanners(state, data) {
      state.banners = data;
    },
    ADD_BANNER(state, banner) {
      state.banners.push(banner);
    },
    DELETE_BANNER(state, type) {
      state.banners = state.banners.filter((banner) => banner.type !== type);
    }
  },
  actions: {
    addBanner({ commit }, banner) {
      commit('ADD_BANNER', banner);
    },
    deleteBanner({ commit }, type) {
      commit('DELETE_BANNER', type);
    },
  },
};
