export const state = {
  total: 0,
  currentPage: 1,
  items: [],
  steps: {},
  parent: {},
  optionSteps: [],
  chatbot: {},
  totalNodes: 0,
};
