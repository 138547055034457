import { ChatBot, Step } from '@modules/chatbots/models';
import { Assistant } from '@modules/chatbots/models/Assistant';
import { useApi, useApiICR } from '@/services/api-icr';

export const actions = {
  async getItems({ commit, state }, params) {
    commit('SET_ITEMS', []);
    commit('loading/updateCounter', 1, { root: true });
    if (params) {
      state.filters = { ...params };
    }

    const { data, ...meta } = await useApiICR().get('chat-bots', state.filters);

    commit('SET_ITEMS', data.data.map(attr => new ChatBot(attr)));
    commit('SET_TOTAL', meta.total ?? 0);
    commit('SET_CURRENT_PAGE', meta.current ?? 0);
    commit('loading/updateCounter', -1, { root: true });
  },
  async saveItem({ commit }, params) {
    commit('loading/updateCounter', 1, { root: true });
    let data;
    if (params.id) {
      data = await useApiICR().put(`chat-bots/${params.id}`, params);
    } else {
      data = await useApiICR().post('chat-bots', {
        ...params,
        coreUrl: import.meta.env.VITE_API_BASE_URL + '/api/v1/',
      });
    }

    commit('loading/updateCounter', -1, { root: true });

    return new ChatBot(data.data);
  },
  async deleteItem({ commit }, value) {
    commit('loading/updateCounter', 1, { root: true });
    await useApiICR().delete(`chat-bots/${value}`);
    commit('REMOVE_ITEM', value);
    commit('loading/updateCounter', -1, { root: true });
  },
  async getChildren(_, params) {
    try {
      const { data } = await useApiICR().get(
        `chat-bots/${params.parentableId}/children`,
        params
      );

      return data.map(child => new Step(child));
    } catch (err) {
      console.warn(err);
      return [];
    }
  },
  async getChatBotDetail({ commit }, params) {
    commit('loading/updateCounter', 1, { root: true });
    const { data } = await useApiICR().get(
      `chat-bots/${params.chatBotId}/details`,
      params
    );

    commit('loading/updateCounter', -1, { root: true });
    const chatBot = new ChatBot(data);
    commit('SET_CHATBOT', chatBot);

    return chatBot;
  },
  async addChild({ dispatch, state }, params) {
    let data;
    if (params.id && params.id !== 'new-step') {
      data = await useApiICR().put(
        `chat-bots/${params.chatBotId}/child/${params.id}`,
        params
      );
    } else {
      data = await useApiICR().post(
        `chat-bots/${params.chatBotId}/child`,
        params
      );

      dispatch('fetchCountTotalNodes', { company: state.chatbot.company });
    }

    return new Step(data?.data || {});
  },
  async removeChild({ commit, dispatch, state }, params) {
    commit('loading/updateCounter', 1, { root: true });
    const data = await useApiICR().delete(
      `chat-bots/${params.chatBotId}/child/${params.id}`
    );
    dispatch('fetchCountTotalNodes', { company: state.chatbot.company });
    commit('loading/updateCounter', -1, { root: true });
    return new Step(data.data);
  },
  async getStep({ commit }, params) {
    commit('loading/updateCounter', 1, { root: true });
    const { data } = await useApiICR().get(
      `chat-bots/${params.chatBotId}/child/${params.id}`
    );
    commit('loading/updateCounter', -1, { root: true });
    if (data.type === 'assistant') {
      return new Assistant(data);
    }
    return new Step(data);
  },
  testRequest(_, body) {
    return useApiICR().post('chat-bots/test-api', body)
      .then(({ data }) => data);
  },
  fetchSurveys(_, params) {
    return useApiICR()
      .get('surveys/autocomplete', params)
      .then(({ data }) => data.map(({ _id: id, name }) => ({ id, name })));
  },
  updatePosition(_, params) {
    return useApiICR().put(`chat-bots/${params.chatBotId}/child/${params.id}`, params);
  },
  sendNewMessage(_, params) {
    return useApiICR().post('v2/messages', params)
      .then(({ data }) => data);
  },
  listAllVariables(_, params) {
    return useApiICR().get('chat-bots/available-variables', params)
      .then(({ data }) => data);
  },
  changeStatusCore({ commit }, values) {
    commit('loading/updateCounter', 1, { root: true });
    try {
      return useApi()
        .put('campaigns/bot-toggle-status', {
          active: values.active,
          ttl: values.conversation?.ttl,
          campaign: values.entityId,
        });
    } finally {
      commit('loading/updateCounter', -1, { root: true });
    }

  },
  fetchCompanyLabels(_, params) {
    if (!params.company) {
      return Promise.resolve([]);
    }

    return useApi().get('clients/labels', params);
  },
  async fetchCountTotalNodes({ commit }, params) {
    const { data } = await useApiICR().get('chat-bots/total-steps', params);

    commit('SET_TOTAL_NODES', data.total);
  },
  async getFlows(_, params) {
    const { data } = await useApiICR().get('chat-bots', params);

    return data.data.map((flow) => ({
      name: flow.name,
      value: flow._id,
    }));
  }
};
