import numeral from 'numeral';
import { useModal } from 'vue-final-modal';
import { createTooltip, destroyTooltip } from 'floating-vue';
import HccConfirmation from '@sellia/base/components/HccModal/index.vue';
import { MEDIA_TYPES } from '@/modules/chatbots/utils/constants';

export function useConfirmation(attrs) {
  return new Promise((resolve) => {
    const { open, close } = useModal({
      component: HccConfirmation,
      slots: {
        default: attrs.description,
      },
      attrs: {
        clickToClose: true,
        escToClose: true,
        hideOnConfirm: false,
        onCancel() {
          resolve({ isConfirmed: false });
          close();
        },
        onConfirm() {
          resolve({ isConfirmed: true });
          close();
        },
        ...attrs,
      }
    });
    open();
  });
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kB', 'mB', 'gB', 'tB', 'PB', 'eB', 'zB', 'yB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function randomString() {
  return Math.random().toString(36).slice(2);
}

export function uuid() {
  return 'xxxxy-yyyxx-xxyyx-yyyxx-xxxyy'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
}

export function timeoutPromise(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}
export async function clipboardSuccess (el, content) {
  const tooltip = createTooltip(el, {
    triggers: [],
    content: content || 'Text copied!',
  });

  tooltip.show();

  await timeoutPromise(1000);
  tooltip.hide();
  await timeoutPromise(400);
  destroyTooltip(el);
}

export function debounce(fn, delay = 300) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
export function formatDataTestId(name) {
  return name.replace(/^\w|[A-Z]|\b\w/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/[\s+.]/g, '');
}

export function validateFiles(files, type, maxSize) {
  let errorMessages = [];

  const filesArray = Array.from(files);

  const allowedMimeTypes = Object.values(MEDIA_TYPES[type.toUpperCase()]).map(item => item.mimeType);
  let validFiles = filesArray.filter(file => allowedMimeTypes.includes(file.type));
  const invalidFiles = filesArray.filter(file => !allowedMimeTypes.includes(file.type));

  if (invalidFiles.length > 0) {
    // Files not supported
    const invalidFileNames = invalidFiles.map(file => file.name);
    errorMessages.push('campaigns.data-bank.format-error');
    validFiles = validFiles.filter(file => !invalidFileNames.includes(file.name));
  }

  const maxSizeInBytes = maxSize * 1024 * 1024;
  const largeFiles = validFiles.filter(file => file.size > maxSizeInBytes);

  if (largeFiles.length > 0) {
    // File exceeded
    const largeFileNames = largeFiles.map(file => file.name);
    errorMessages.push(`campaigns.data-bank.${type.toLowerCase()}.size-error`);
    validFiles = validFiles.filter(file => !largeFileNames.includes(file.name));
  }

  if (validFiles.length > 10) {
    validFiles = validFiles.slice(0, 10);
    errorMessages.push('campaigns.data-bank.max-files-error');
  }

  return { validFiles, errorMessages };
}

export function createEmptyCard(type, file, order,campaign) {
  return  {
    id: '',
    type,
    order,
    campaign,
    [type]: {
      size: file.size,
      ext: file.name.split('.').pop(),
      mimetype: file.type,
      thumbnail: '',
      url: '',
      filename: file.name,
    },
    progress: 0,
    error: false,
    file
  };
}

export function getHtmlTemplateParams(text, chatBotId) {
  return text
    .replace(/\r\n|\r|\n/g, '<br>')
    .replace(
      /\{\{\d+}}/ig,
      function replaceParam(match) {
        const index = +match.replace(/[{}]/ig, '') - 1;

        return `<TemplateParameterInput
          v-tooltip="textParam(${index})"
          :index="${index}"
          :editable="editable"
          chat-bot-id="${chatBotId}"
          :value="params[${index}]"
          @save="onSave"
        />`;
      }
    );
}

export function numFormat(value, format = '0,0') {
  return numeral(value).format(format);
}
