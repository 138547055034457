export default (i18n) => {
  return {
    path: '/channels',
    component: () => import('@modules/channels/views/ChannelsInitialView.vue'),
    name: 'module-channels',
    meta: {
      priority: 4,
      site: {
        t: 'app.channels',
      },
    },
    children: [
      {
        path: '',
        name: 'channels',
        component: () => import('@modules/channels/views/List.vue'),
        meta: {
          priority: 4.1,
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            name: i18n.global.t('app.channels'),
          },
          requiredPermissions: [
            'channels_read',
            'channels_write',
            'channels_assign_channel',
            'templates_write_text',
            'templates_write_document',
            'templates_write_image',
            'templates_write_video',
            'templates_read',
          ],
        },
      },
      {
        path: ':companyId/channel-setting',
        name: 'channels-new-channel',
        component: () => import('@modules/channels/views/Form.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            name: i18n.global.t('app.channels'),
            t: 'channels-settings.add',
          },
        },
      },
      {
        path: ':companyId/:channelId',
        component: () => import('@modules/DefaultView.vue'),
        meta: {
          site: {
            t: (store, { channelId }) => {
              const channels = store.getters['shared/allChannelsByCompany'];
              const currentChannel = channels.find((channel) => channel.id === channelId);

              return currentChannel?.name || '-';
            },
          }
        },
        children: [
          {
            path: 'edit',
            name: 'channels-edit-channel',
            component: () => import('@modules/channels/views/Form.vue'),
            meta: {
              forAuth: true,
              layout: 'navigation-layout',
              requiredPermissions: ['channels_write'],
              site: {
                name: i18n.global.t('app.channels'),
              },
            },
          },
          {
            path: 'qr-codes',
            name: 'channels-qr-codes',
            component: () => import('@modules/channels/views/ChannelQR.vue'),
            meta: {
              forAuth: true,
              layout: 'navigation-layout',
              requiredPermissions: ['channels_write'],
              site: {
                t: 'channels-settings.qr-codes',
              },
            },
          },
          {
            path: 'templates',
            name: 'channels-whatsapp-templates',
            component: () => import('@modules/channels/views/Template.vue'),
            meta: {
              forAuth: true,
              layout: 'navigation-layout',
              requiredPermissions: [
                'templates_write_text',
                'templates_write_document',
                'templates_write_image',
                'templates_write_vide',
                'templates_read',
              ],
              site: {
                name: i18n.global.t('app.channels'),
                t: 'channels-settings.template-configuration',
              },
            },
          },
        ],
      },
    ],
  };
};
